var currentEvent;
var calendar;

window.renderCalendar = (eventsUrl) => {
    var calendarEl = document.getElementById('calendar');
    calendar = new FullCalendar.Calendar(calendarEl,
    {
        plugins: [ dayGridPlugin, listPlugin, interactionPlugin ],
        initialView: detectMobile() ? 'listMonth' : 'dayGridMonth',
        weekNumbers: true,
        firstDay: 1,
        locale: nlLocale,
        events: eventsUrl,
        eventClick: handleEventClick,
        eventDidMount: handleDidMount,
        headerToolbar: {
            left: 'dayGridMonth,listMonth',
            center: 'title',
            right: 'prev,next'
        },
        views: {
            dayGridMonth: {
                dayHeaderFormat: {
                    weekday: 'long',
                },
            }
        }
    });
    calendar.render();
}

window.renderCalendarForPlanner = (eventsUrl) => {
    var calendarEl = document.getElementById('calendar');
    calendar = new FullCalendar.Calendar(calendarEl,
    {
        plugins: [ dayGridPlugin, listPlugin, interactionPlugin ],
        initialView: detectMobile() ? 'listMonth' : 'dayGridMonth',
        weekNumbers: true,
        firstDay: 1,
        locale: nlLocale,
        events: function(info, successCallback, failureCallback) {
            let eventTypeId = document.querySelector("#eventType").value;
            axios.get(eventsUrl + "/" + eventTypeId, { params: {start: info.start, end: info.end}})
                .then((response) => {
                    //console.log(response.data);
                    return successCallback(response.data);
                })
                .catch((err) => {
                    //console.error(err);
                    failureCallback(err);
                })
        },
        eventClick: handleEventClick,
        eventDidMount: handleDidMount,
        headerToolbar: {
            left: 'dayGridMonth,listMonth',
            center: 'title',
            right: 'prev,next'
        },
        views: {
            dayGridMonth: {
                dayHeaderFormat: {
                    weekday: 'long',
                },
            }
        }
    });
    calendar.render();
}

window.changeEvents = () => {
    calendar.refetchEvents();
}

window.handleDidMount = (info) => {
    $(info.el).tooltip({ 
        title: info.event.extendedProps.users,
        placement: "bottom",
        trigger: "hover",
        container: "body",
        html: true
    });
}

if ($('#eventModal').length > 0) {
    var modal = new bootstrap.Modal(document.querySelector('#eventModal'));
    var participantsList = document.querySelector("#participantsList");

    window.handleEventClick = (calEvent, jsEvent, view) => {
        axios.get("/api/event/" + calEvent.event.id)
            .then((response) => {
                currentEvent = response.data;

                if(document.querySelector('#announcement') !== null) {
                    document.querySelector('#announcement').value = "";
                }

                let timePeriod = new Date(currentEvent.start_time).toLocaleTimeString() + " tot " + new Date(currentEvent.end_time).toLocaleTimeString();
                if(currentEvent.all_day) {
                    timePeriod = "Ganse dag";
                }

                document.querySelector('#modalTitle').textContent = currentEvent.title;
                document.querySelector('#eventCreator').textContent = currentEvent.creator.name;
                document.querySelector('#timePeriod').textContent = timePeriod;
                document.querySelector('#eventDescription').textContent = currentEvent.description != null ? currentEvent.description : "—";

                if(checkIfDateIsInThePast(currentEvent.start_time)) {
                    document.querySelector('#participateDiv').classList.add('d-none');
                    document.querySelector('#participateDiv').classList.remove('d-block');
                } else {
                    document.querySelector('#participateDiv').classList.add('d-block');
                    document.querySelector('#participateDiv').classList.remove('d-none');
                }

                fillParticipantsList(participantsList, currentEvent.participants);
            });
        modal.show();
    }
}
if ($('#plannerModal').length > 0) {
    var modal = new bootstrap.Modal(document.querySelector('#plannerModal'));
    var participantsList = document.querySelector("#participantsList");

    window.handleEventClick = (calEvent, jsEvent, view) => {
        axios.get("/api/event/" + calEvent.event.id)
            .then((response) => {
                currentEvent = response.data;
                //console.log(currentEvent.description);

                if(document.querySelector('#announcement') !== null) {
                    document.querySelector('#announcement').value = "";
                }

                let timePeriod = new Date(currentEvent.start_time).toLocaleTimeString() + " tot " + new Date(currentEvent.end_time).toLocaleTimeString();
                if(currentEvent.all_day) {
                    timePeriod = "Ganse dag";
                }

                document.querySelector('#modalTitle').textContent = currentEvent.title;
                document.querySelector('#eventCreator').textContent = currentEvent.creator.name;
                document.querySelector('#timePeriod').textContent = timePeriod;
                document.querySelector('#eventDescription').textContent = currentEvent.description != null ? currentEvent.description : "—";
                fillParticipantsList(participantsList, currentEvent.participants);
            });
        modal.show();
    }
}

if ($('#errorModal').length > 0) {
    var errorModal = new bootstrap.Modal(document.querySelector('#errorModal'));

    window.toggleCurrentParticipant = () => {
        //console.log(currentEvent.id);
        let announcement = document.querySelector('#announcement').value;
        axios.post("/api/event/toggle_participant/" + currentEvent.id, {announcement: announcement})
            .then((response) => {
                //console.log(response.data);
                document.querySelector('#announcement').value = "";
                fillParticipantsList(participantsList, response.data);
                calendar.refetchEvents();
            })
            .catch((err) => {
                if(err.response.status == 403) {
                    document.querySelector('#errorModalBody').innerHTML = "De handeling die u probeert te doen is niet toegelaten, meer info over de voorwaardes om uit te schrijven kan je vinden in de handleiding.";
                    errorModal.show();
                }
            });
    }
    
    window.togglePlannedParticipant = () => {
        //console.log(currentEvent.id);
        let announcement = document.querySelector('#announcement').value;
        let participant_id = document.querySelector('#participants').value;
        if( participant_id != -1) {
            axios.post("/api/event/toggle_planned_participant/" + currentEvent.id, {participant_id: participant_id, announcement: announcement})
                .then((response) => {
                    //console.log(response.data);
                    document.querySelector('#announcement').value = "";
                    document.querySelector('#participants').value = -1;
                    fillParticipantsList(participantsList, response.data);
                    calendar.refetchEvents();
                })
                .catch((e) => {
                    document.querySelector('#errorModalBody').innerHTML = "Er liep iets mis bij het toevoegen.<br>De deelnemer is niet toegevoegd aan het event.";
                    errorModal.show();
                });
        } else {
            document.querySelector('#errorModalBody').innerHTML = "Er is geen deelnemer geselecteerd";
            errorModal.show();
        }
    }
}

window.fillParticipantsList = (ulList, list) => {
    clearList(ulList);
    if(list.length > 0) {
        for(participant in list) {
            //console.log(list[participant].pivot.announcement);
            let classes = "list-group-item";
            if(list[participant].pivot.announcement) {
                classes += " border border-danger";
            }
            addToList(ulList, list[participant].name , classes, list[participant].pivot.announcement);
        }
    } else {
        addToList(ulList, "Nog geen deelnemers", "list-group-item bg-danger text-light");
    }
}